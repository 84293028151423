import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';

import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthProvider';
import { DecodedToken } from '../../interfaces/DecodedToken';
import { callGetFeatureFlagsApi, login } from '../../services/api';
import IcamTextField from '../common/IcamTextField';

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { setUser } = useAuthContext();
  const navigate = useNavigate();
  const [isCaseFeatureEnabled, setIsCaseFeatureEnabled]=useState<boolean>(true);
  const [caseFeatureFlag] = useState<string>('CASE_FEATURE');

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      // TODO beware: directing to cases for testing. don't push to prod
      // if (process.env.REACT_APP_FEATURE_CASE === 'true') {
      navigate('/cases');
      // } else {
      //   navigate('/home');
      // }
    }
  }, [navigate]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    try {
      const response = await login(email, password);
      const token = response.data.token;

      localStorage.setItem('token', token);
      // Decode token and set the user context
      const decodedToken: DecodedToken = jwtDecode(token);
      setUser(decodedToken);
      setMessage('Login successful');
      setLoading(false);
      const getFeatureFlag = async () => {
        try {
          const response = await callGetFeatureFlagsApi(caseFeatureFlag);
          setIsCaseFeatureEnabled(response.data.active as boolean);
        } catch (error) {
          console.log('Error getting feature flags: ', error);
          throw error;
        } finally {
        setLoading(false);
        }
      };
      getFeatureFlag();
      if (isCaseFeatureEnabled) {
        navigate('/cases');
      } else {
        navigate('/home');
      }
    } catch (err: any) {
      setLoading(false);
      setMessage(err.response?.data?.message || 'An error occurred');
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box sx={{ height: '100vh', display: 'flex' }}>
      <Grid container sx={{ height: '100%', width: '100%' }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 4,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 16,
              left: 16,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src="/icam_expert_logo.svg"
              alt="Incident AI Logo"
              style={{ height: 40, marginRight: 8 }}
            />
            <Typography
              variant="h5"
              component="div"
              sx={{
                fontFamily: 'Inter, Arial, sans-serif',
                fontSize: '1.2rem',
                fontWeight: 'bold',
              }}
            >
              Incident AI
            </Typography>
          </Box>
          <Box sx={{ width: '100%', maxWidth: '400px' }}>
            <form onSubmit={handleLogin}>
              <Grid
                container
                spacing={3}
                direction="column"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ fontWeight: '400' }}
                  >
                    Sign in to Incident AI
                  </Typography>
                </Grid>
                <Grid item>
                  <IcamTextField
                    label="Email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <IcamTextField
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                            sx={{ color: 'F5F5F5' }}
                          >
                            {showPassword ? (
                              <VisibilityOff sx={{ fontSize: '1.2rem' }} />
                            ) : (
                              <Visibility sx={{ fontSize: '1.2rem' }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={loading}
                    sx={{
                      mt: 2,
                      backgroundColor: 'rgb(123, 31, 162)',
                      '&:hover': {
                        backgroundColor: 'rgb(156, 39, 176)',
                      },
                      '&.Mui-disabled': {
                        backgroundColor: 'rgb(123, 31, 162)',
                        color: 'rgba(255, 255, 255, 0.8)',
                      },
                    }}
                  >
                    {loading ? (
                      <CircularProgress
                        size={24}
                        sx={{ color: 'rgba(255, 255, 255, 0.8)' }}
                      />
                    ) : (
                      'Login'
                    )}
                  </Button>
                </Grid>
                {message && (
                  <Grid item>
                    <Alert
                      severity={
                        message === 'Login successful' ? 'success' : 'error'
                      }
                    >
                      {message}
                    </Alert>
                  </Grid>
                )}
              </Grid>
            </form>
          </Box>
        </Grid>

        <Grid
          item
          xs={false}
          md={6}
          sx={{
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <img
            src="/vision.png"
            alt="Vision"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginForm;
